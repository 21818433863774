import imageCompression from 'browser-image-compression';

export const compressedFile = {
  methods: {
    compressed(file, maxSizeMB, maxWidthOrHeight) {
        const options = {
          maxSizeMB: maxSizeMB,
          maxWidthOrHeight: maxWidthOrHeight
        }

        return new Promise((resolve, reject) => {
            imageCompression(file, options)
                .then(compressedFile => {
                    resolve(compressedFile)
                })
                .catch(error => {
                    console.log(error.message);
                });
        })

      }
  }
}
