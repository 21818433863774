<template>
  <main class="navbar-offset-tablet salon-flow">
    <PageNavigator
      :page-title="
        isUpdateProfilePage
          ? $tc('salon-employees.breadcrumbs-update-label', 2)
          : $tc('salon-employees.breadcrumbs-create-label', 2)
      "
      :has-back="true"
      :button-back-link="'/salon/staff'"
      class="pr-1 text-nowrap"
    >
      <template v-slot:action>
        <div class="w-100 d-flex justify-content-between">
          <div>
            <p id="service-info-1" class="ml-0-5 pr-1 mt-1 mb-0">
              <font-awesome-icon
                v-b-tooltip
                class="fa-w-16 ml-0-5 tooltipInfoIcon"
                style="font-size:20px;"
                data-toggle="tooltip"
                :icon="['fas', 'info-circle']"
              ></font-awesome-icon>
            </p>
            <b-tooltip
              target="service-info-1"
              :popover-style="{ color: '#ffffff !important', background: '#fff !important' }"
              :custom-class="'show'"
              class="mr-5 show"
              triggers="focus hover"
              placement="right"
            >
              <div class="text-left">
                <span class="mb-2" v-html="$t('salon-employees.tooltip.roles_1')"></span>
                <br />
                <span class="mb-2" v-html="$t('salon-employees.tooltip.roles_2')"></span>
                <br />

                <span class="mb-2" v-html="$t('salon-employees.tooltip.roles_3')"></span>
                <br />
              </div>
            </b-tooltip>
          </div>

          <div>
            <button
              v-if="isManagerUpdateProfilePage"
              class="btn btn-checkbox px-1-5 py-0-8 mr-1-5"
              @click="deleteManager({ id: managerId, role: 'Manager' })"
            >
              <font-awesome-icon :icon="['fas', 'trash-alt']" class="fa-w-14 mr-0-5"></font-awesome-icon>

              <span class="font-weight-regular roboto-font text-black">{{ $t('common.delete-btn') }}</span>
            </button>
            <button
              :disabled="areFieldsEmpty || (isManagerUpdateProfilePage && !fieldsToChange)"
              class="btn btn-primary px-4 py-1"
              @click="submitForm"
            >
              <span class="font-weight-medium poppins-font">{{ $t('common.submit-btn') }}</span>
            </button>
          </div>
        </div>
      </template>
    </PageNavigator>
    <div class="px-1-5 pt-7-5 full-page-height">
      <div v-if="!isWorkerProfileLoading" class="h-100">
        <form>
          <div class="row">
            <div class="col-12">
              <p class="font-weight-medium poppins-font mb-1-5 text-black">
                {{ $t('salon-employees.form-label.section_label_personal') }}
              </p>
            </div>
            <div class="col-auto pr-2-5">
              <div class="d-flex">
                <div class="position-relative edit-profile-img px-0 mb-3-5">
                  <img :src="profilePhotoToDisplay" alt class="w-100 h-100 image--cover rounded" />
                  <div class="position-absolute">
                    <input
                      id="customFile"
                      ref="profileImage"
                      v-validate="'ext:jpeg,jpg,png'"
                      type="file"
                      accept="image/x-png,image/jpg,image/jpeg"
                      name="photo"
                      :data-vv-as="$t('salon-gallery.photo')"
                      class="custom-file-upload"
                      @change="onImageUpload"
                    />
                    <label class="custom-file-label" for="customFile">
                      <span class="photocamera-icon"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="row">
                <div v-for="(field, key) in managerProfileInfoFields" :key="field.name" class="col-12 col-md-6">
                  <label :for="field.name" class="poppins-font text-black font-weight-medium text-small">{{
                    $t(field.i18KeyText)
                  }}</label>
                  <div :class="{ 'mb-1-5': !errors.has(field.name), 'input-group': field.name === 'password' }">
                    <input
                      :id="field.name"
                      v-model="field.value"
                      v-validate="
                        isUpdateProfilePage && field.name === 'password'
                          ? field.value > 0
                            ? 'min:8|max:16'
                            : 'min:0|max:16'
                          : field.validationRules
                      "
                      :name="field.name"
                      :type="field.type"
                      :data-vv-as="$t(field.i18KeyText)"
                      autocomplete="new-password"
                      class="form-control w-100 px-1-5"
                    />
                    <div v-if="field.name === 'password'" class="input-group-append cursor-pointer" @click="changeType">
                      <span class="input-group-text text-gray bg-white px-1-5 py-0-5 border-left-0">
                        <font-awesome-icon :icon="['fas', passwordIcon]"></font-awesome-icon>
                      </span>
                    </div>
                  </div>
                  <span v-show="errors.has(field.name)" class="error-message my-1 col-12 pl-0">{{
                    errors.first(field.name)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import PageNavigator from '@/components/PageNavigator'
import { compressedFile } from '@/_mixins/compressedFile.js'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SalonStaffsForm',
  components: { PageNavigator },
  mixins: [compressedFile],
  data() {
    return {
      profilePhotoToDisplay: '',
      isWorkerProfileLoading: false,
      avatar: null,
      managerProfileInfoFields: [
        {
          name: 'first_name',
          value: '',
          validationRules: 'required|alpha',
          i18KeyText: 'common.forms.label.auth.first_name',
          type: 'text'
        },
        {
          name: 'last_name',
          value: '',
          validationRules: 'required|alpha',
          i18KeyText: 'common.forms.label.auth.last_name',
          type: 'text'
        },
        {
          name: 'email',
          value: '',
          validationRules: 'required|email',
          i18KeyText: 'common.forms.label.auth.email',
          type: 'email'
        },
        {
          name: 'password',
          value: '',
          validationRules: 'required|min:8|max:16',
          i18KeyText: 'common.forms.label.auth.password',
          type: 'password'
        }
      ],
      servicessPayloadData: {
        salon_id: null,
        page: 1,
        sort: '-id'
      },
      deleteLastInfoMessage: {
        type: 'info',
        messageI18Key: 'common.alerts.actions.delete_last_info'
      },
      deleteErrorMessage: {
        type: 'error',
        messageI18Key: 'common.alerts.actions.global_error'
      }
    }
  },
  computed: {
    ...mapGetters({
      parametersValues: 'haircutParams/getParametersValues',
      accountInfo: 'user/getAccountInfo',
      workerProfileInfo: 'salonsStaff/getProfileInfo',
      userRole: 'user/getRole'
    }),
    isUpdateProfilePage() {
      return this.$router.currentRoute.name === 'manager-update'
    },
    passwordIcon() {
      let passwordField = this.managerProfileInfoFields.find(field => field.name === 'password')

      return passwordField.type === 'password' ? 'eye' : 'eye-slash'
    },
    staffId() {
      return this.$route.params.id
    },
    isManagerUpdateProfilePage() {
      return this.$router.currentRoute.name === 'manager-update'
    },
    areFieldsEmpty() {
      return this.managerProfileInfoFields.filter(field => field.name !== 'password').find(field => !field.value)
    },
    managerId() {
      return this.$router.currentRoute.params.id
    },
    fieldsToChange() {
      let fieldsToChange = {}
      let workerAvatar = ''

      if (!this.workerProfileInfo) return null

      workerAvatar = this.workerProfileInfo.avatar ? this.workerProfileInfo.avatar.path : ''

      if (this.profilePhotoToDisplay !== workerAvatar) fieldsToChange.avatar = this.avatar

      this.managerProfileInfoFields.forEach((infoField, index) => {
        if (infoField.name !== 'password') fieldsToChange[infoField.name] = infoField.value
      })

      if (this.managerProfileInfoFields[3].value) fieldsToChange.password = this.managerProfileInfoFields[3].value
      return Object.keys(fieldsToChange).length ? fieldsToChange : null
    },
    managerPayload() {
      let formData = new FormData()
      const [firstName, lastName, email, password] = this.managerProfileInfoFields
      const managerId = this.managerId
      const salonId = this.accountInfo.salon_id
      const managerUpdatePayloadObject = { ...this.fieldsToChange, manager_id: managerId, salon_id: salonId }

      let addManagerPayloadObject = {
        salon_id: salonId,
        first_name: firstName.value,
        email: email.value,
        last_name: lastName.value,
        password: password.value
      }

      if (this.avatar !== null) {
        addManagerPayloadObject = {
          ...addManagerPayloadObject,
          ...{ avatar: this.avatar }
        }
      }

      const payloadObject = this.isManagerUpdateProfilePage ? managerUpdatePayloadObject : addManagerPayloadObject
      for (const dataField in payloadObject) {
        formData.append(dataField, payloadObject[dataField])
      }
      return formData
    }
  },
  mounted() {
    if (this.isManagerUpdateProfilePage) {
      this.isWorkerProfileLoading = true
      this.getManagerProfileInfo(this.$router.currentRoute.params.id)
        .then(() => {
          const storedProfileInfo = { ...this.workerProfileInfo }
          this.setProfileInfoFields(storedProfileInfo)
          this.isWorkerProfileLoading = false
        })
        .catch(() => {
          this.setError({
            type: 'profile_info_loading',
            messageI18Key: 'salon-manager-flow.manager_profile_info_loading'
          })
          this.$router.push('/salon/staff')
        })
    }
  },
  methods: {
    ...mapActions({
      addManager: 'salonsStaff/createManager',
      addTopManager: 'salonsStaff/createTopManager',
      updateManagerProfileInfo: 'salonsStaff/updateManagerProfileInfo',
      getManagerProfileInfo: 'salonsStaff/getManagerProfileInfo',
      deleteManagerAction: 'salonsStaff/deleteManager',
      setError: 'userMessages/setError',
      setSuccess: 'userMessages/setSuccess'
    }),
    changeType() {
      let passwordField = this.managerProfileInfoFields.find(field => field.name === 'password')

      passwordField.type === 'password' ? (passwordField.type = 'text') : (passwordField.type = 'password')
    },
    submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.isWorkerProfileLoading = true
          if (this.isManagerUpdateProfilePage) {
            // TODO: add error message if email already taken
            this.updateManagerProfileInfo(this.managerPayload)
              .then(() => {
                this.isWorkerProfileLoading = false
                this.setSuccess({
                  messageI18Key: 'salon-manager-flow.manager_update_success',
                  type: 'successful_update'
                })
                this.$router.push('/salon/staff')
              })
              .catch(errors => {
                this.isWorkerProfileLoading = false

                for (let property in errors.data.messages) {
                  if (property === 'email') {
                    this.$validator.errors.add({ field: 'email', msg: this.$t('common.error.wrong_email') })
                  }
                }

                this.setError({
                  type: 'sig_up_error',
                  messageI18Key: 'salon-manager-flow.manager_update_error'
                })
              })
          } else if (!this.isManagerUpdateProfilePage) {
            this.addManager(this.managerPayload)
              .then(() => {
                this.isWorkerProfileLoading = false
                this.setSuccess({
                  messageI18Key: 'salon-manager-flow.manager_create_success',
                  type: 'successful_creation'
                })
                this.$router.push('/salon/staff')
              })
              .catch(errors => {
                this.isWorkerProfileLoading = false

                for (let property in errors.data.messages) {
                  if (property === 'email') {
                    this.$validator.errors.add({ field: 'email', msg: this.$t('common.error.wrong_email') })
                  }
                }

                this.setError({
                  type: 'sig_up_error',
                  messageI18Key: 'salon-manager-flow.manager_create_error'
                })
              })
          }
        } else {
          let error = this.$validator.errors.items[0]
          let message = error.msg

          if (error.rule === 'ext') {
            message = message + this.$t('common.alerts.actions.format_error')

            this.setError({
              type: 'error',
              message: message
            })
          }

          if (error.rule === 'size') {
            this.setError({
              type: 'error',
              message: message
            })
          }
        }
      })
    },
    deleteManager(managerData) {
      this.isWorkerProfileLoading = true
      this.deleteManagerAction(managerData)
        .then(() => {
          this.isWorkerProfileLoading = false
          this.$router.push('/salon/staff')
          this.setSuccess({
            messageI18Key: 'salon-manager-flow.manager_delete_success',
            successCode: 200,
            successExists: true
          })
        })
        .catch(() => {
          this.isWorkerProfileLoading = false

          this.setError(this.deleteErrorMessage)
        })
    },
    setProfileInfoFields(storedProfileInfo) {
      if (!storedProfileInfo) return
      const { avatar } = storedProfileInfo
      this.managerProfileInfoFields = this.managerProfileInfoFields.map((infoField, index) => ({
        ...infoField,
        value: storedProfileInfo.textFields[index]
      }))
      this.profilePhotoToDisplay = avatar ? avatar.path : ''
    },
    onImageUpload({ target }) {
      if (!target.files.length) return
      this.$validator.validate('photo').then(result => {
        if (result) {
          const reader = new FileReader()
          reader.onload = e => {
            this.profilePhotoToDisplay = e.target.result
          }

          this.compressed(target.files[0], 5, 330)
            .then(file => {
              this.avatar = file
              reader.readAsDataURL(target.files[0])
            })
            .catch(errors => console.log(errors))
        } else {
          let error = this.$validator.errors.items[0]
          let message = error.msg

          if (error.rule === 'ext') {
            message = message + this.$t('common.alerts.actions.format_error')

            this.setError({
              type: 'error',
              message: message
            })
          }

          if (error.rule === 'size') {
            this.setError({
              type: 'error',
              message: message
            })
          }
        }
      })
    }
  }
}
</script>
